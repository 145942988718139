<template>
  <div></div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {provider} from '../../services'
import * as types from '../../store/modules/auth/types'

export default {
  name: "shopify-complete",
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
    })
  },
  methods: {
    ...mapActions({
      setToken: `auth/${types.SET_TOKEN}`,
    })
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        this.$router.push({name: 'base'})
      }
    },
  },
  created: function () {
    localStorage.setItem('shop_name', JSON.stringify(this.$route.query.shop));
    this.setToken({data: {...this.$route.query}, provider: provider.shopify});
  }
}
</script>

<style scoped>

</style>